.BSA-disable {
  opacity: 0.5;
}

.BSA-II-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .BSA-header-title {
    display: flex;
    gap: 5px;
    align-items: center;
    span {
      font-size: 15px;
      font-weight: 300;
    }
    .BSA-doc-month-req {
      font-size: 10px;
      height: 17px;
      padding-inline: 5px;
      border-radius: 3px;
      color: #20a74e;
      border: 1px solid #20a74e;
    }
    @media (max-width: 768px) {
      display: grid;
    }
  }
  .ant-upload-select {
    height: auto !important;
    span {
      border: none !important;
      background-color: transparent !important;
      color: #c71c8e !important;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.BSA-II-document-header {
  div {
    font-weight: 300;
  }
}

.BSA-input-item {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  margin-bottom: 12px;
  &.BSA-error {
    background: #E121211F;
  }
}

.BSA-II-document {
  padding: 10px;
  display: flex;
  .ant-form-item {
    width: 100%;
  }
  .BSA-II-file-name {
    width: 30%;
    align-items: center;
    gap: 10px;
    .ant-checkbox {
      top: 0px;
    }
    .ant-form-item {
      width: 100%;
    }
    span {
      line-break: anywhere;
    }
  }
  .BSA-II-format {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .anticon-info-circle {
      color: gray;
      margin-top: 2px;
    }
  }
  .BSA-II-maxsize {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .anticon-info-circle {
      color: gray;
      margin-top: 3px;
    }
  }
  .BSA-II-action {
    width: 10%;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    display: grid;
    .ant-form-item {
      width: 100%;
    }
    .BSA-II-file-name {
      width: auto;
      span {
        flex: 1;
      }
      .ant-form-item {
        width: 100%;
      }
    }
    .BSA-II-format,
    .BSA-II-maxsize {
      width: auto;
      justify-content: start;
    }
    .BSA-II-action {
      width: 100%;
      .ant-btn {
        width: 100%;
      }
    }
  }
}

.BSA-II-document-bank {
  background: #f5f7fa;
  display: grid;
  .BSA-II-document-bank-row {
    display: flex;
    gap: 10px;
    .BSA-II-file-name {
      width: 40%;
    }
    .BSA-II-format {
      width: 41%;
    }
    .BSA-II-maxsize {
      width: 50%;
    }
    .BSA-II-emp-name {
      width: 60%;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    gap: 10px;
    .BSA-II-document-bank-row {
      display: grid;
      gap: 10px;
      .BSA-II-file-name,
      .BSA-II-format,
      .BSA-II-maxsize,
      .BSA-II-emp-name {
        width: 100%;
      }
    }
    .mt-10 {
      margin-top: 0px;
    }
  }
}

.BSA-II-statements {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: -8px;
  span {
    font-size: 12px;
    padding-top: 2px;
  }
  .ant-btn-text {
    padding-inline: 5px;
    height: 22px;
    line-height: 0;
    .collapse-data-icon {
      transform: rotate(180deg);
    }
  }
  .BSA-II-statement-data {
    .BSA-II-statements-details {
      display: flex;
      gap: 10px;
      span {
        font-size: 12px;
        padding-top: 3px;
        height: 19px;
      }
      .BSA-II-statements-months {
        height: 19px;
        .BSA-II-statements-month {
          padding-block: 2px;
          padding-inline: 8px;
          border: 1.5px solid #9d9d9d;
          border-right-width: 0px;
          // border-bottom-width: 0px;
          font-size: 12px;
          &.month-verify {
            border-color: #20a74e;
            color: #20a74e;
          }
        }
      }
      &:first-child {
        .BSA-II-statements-months {
          .BSA-II-statements-month {
            &:first-child {
              border-top-left-radius: 4px;
            }
            &:last-child {
              border-top-right-radius: 4px;
              border-right-width: 1.5px;
            }
          }
        }
      }
      &:last-child {
        .BSA-II-statements-months {
          .BSA-II-statements-month {
            &:first-child {
              border-bottom-left-radius: 4px;
            }
            &:last-child {
              border-bottom-right-radius: 4px;
              border-right-width: 1.5px;
            }
            border-bottom-width: 1.5px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    display: grid;
  }
}

.footer-btn {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  .ant-btn {
    width: 100px;
  }
}

.delete-modal {
  padding-block: 20px;
  padding-inline: 30px;
  display: grid;
  span {
    text-align: center;
  }
  .delete-modal-btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}


