@import "../../styles/theme-colors.scss";

.main-div {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.list-Filter-By {
  .ant-list-item {
    padding-inline: 14px;
  }
}

.devider-space {
  margin-block: 10px;
}

.card-style {
  margin-top: 30px;

  .filter-btn {
    width: 100%;
  }
}

.card-column {
  margin-top: 30px;

  .card-title {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .card-value-title {
    display: flex;
    justify-content: center;
    color: $primary;
    font-weight: 800;
  }

  .card-input-div {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 10px;

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.divider-class {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .divider-hight {
    height: 90%;
  }
}

.summary-div {
  padding: 25px;

  .summary-title {
    margin-top: 30px;
    color: $primary;
  }

  .price-style {
    display: flex;
    justify-content: space-between;
  }

  .total-price-style {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .buyLead-btn {
    margin-top: 10px;
    padding-block: 20px;
    
    .buyLead-width {
      width: 100%;
    }
  }
}

.couponsModal {
  .ant-modal-content {
    padding: 10px 0px !important;
  }
}

.modal-title {
  display: flex;
  justify-content: center;
}

.coupon-list {
  .ant-list {
    padding: 15px !important;

    .ant-list-item {
      padding: 0 !important;
    }
  }
}

.modal-card {
  border-style: dashed;
  border-color: gray;

  .modal-list-item {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
  }

  .modal-item-header {
    color: $primary;
    margin-bottom: auto;
  }

  .apply-but-div {
    padding: 7px 0;

    .apply-btn {
      color: $secondary;
    }
  }

  .mobileView-apply-but {
    padding: 18px 0;

    .apply-btn {
      color: $secondary;
    }
  }
}

.billing-div {
  padding-inline: 12px;
  .billing-price-style {
    display: flex;
    justify-content: space-between;
  }

  .total-price-style {
    display: flex;
    justify-content: space-between;
  }
}

.otp-btn {
  height: 40px;
  margin-top: 21px;
  background-color: $primary;
  float: right;
}

.otp-btn-login {
  height: 40px;
  margin-top: 21px;
  background-color: $primary;
  width: 200px
}

.lead-table.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: $primary;
  color: white !important;
}

.lead-table {
  margin-top: 5px;
  overflow: auto;

  .ant-table-cell::before {
    background-color: transparent !important;
  }
}

.buy-lead-offer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .logo {
    display: flex;
    justify-content: center;
  }
  .content {
    width: 100%;
    color: green;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .thenkyou {
      font-size: 30px;
      margin-bottom: 0px;
      margin-top: 10px;
    }
    .message {
      font-size: 20px;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
}

.billing-details {
  .ant-card-body {
    padding: 20px !important;
  }
}
